.alert {
    --background: var(--alert_background, var(--color-white));
    --color: var(--alert_color, --global-text);
    --section-color: var(--alert_section-color, --global-text);
    --box-shadow: var(--color-black);
    --button-width: 3rem;
    --button-hover-background: var(--color-black);
    --progress-background: var(--color-black);
    width: 75vw;
    max-width: 600px;
    padding: var(--gap) calc(var(--button-width) + var(--gap)) var(--gap) var(--gap);
    position: fixed;
    top: 4vh;
    right: 4vh;
    z-index: var(--zindex-alert);
    overflow: hidden;
    background: hsl(var(--background));
    color: hsl(var(--color));
    box-shadow: 0 0.8px 6.4px hsla(var(--box-shadow), 0.017), 0 2px 15.3px hsla(var(--box-shadow), 0.024), 0 3.8px 28.8px hsla(var(--box-shadow), 0.03), 0 6.7px 51.4px hsla(var(--box-shadow), 0.036), 0 12.5px 96.1px hsla(var(--box-shadow), 0.043), 0 30px 230px hsla(var(--box-shadow), 0.06);
    border-radius: var(--global-border-radius);
}

.header {
    margin-bottom: calc(var(--gap) / 2);
    display: flex;
    align-items: center;

    + .section {
        padding-left: calc(var(--gap) / 2);
    }
}

.title {
    padding-left: calc(var(--gap) / 2);
    font-size: var(--font-size-1);
}

.section {
    color: hsl(var(--section-color));
}

.button {
    width: var(--button-width);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    color: hsl(var(--color));
    font-size: var(--font-size-3);
    font-weight: 400;
    transition-property: background-color, color, border-color;
    transition-duration: var(--transition-out);
    transition-timing-function: var(--transition-timing);
    border-left: 1px solid hsla(var(--color-black), 0.05);

    &:focus,
    &:hover {
        background-color: hsla(var(--button-hover-background), 0.05);
        color: currentcolor;
        border-color: hsla(var(--button-hover-background), 0.05);
    }
}

.progress {
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: hsla(var(--progress-background), 0.25);
}

.backdrop {
    --backdrop-background: var(--color-black);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: calc(var(--zindex-alert) - 1);
    background: hsla(var(--backdrop-background), 0.1);
}

.success {
    --variant: var(--global_success-color, var(--color-green));
}

.info {
    --variant: var(--global_info-color, var(--color-blue));
}

.warning {
    --variant: var(--global_warning-color, var(--color-yellow));
}

.danger {
    --variant: var(--global_danger-color, var(--color-red));
}

.success,
.info,
.warning,
.danger {
    --color: var(--variant);
    --box-shadow: var(--variant);
    --backdrop-background: var(--variant);
    --button-hover-background: var(--variant);
    --progress-background: var(--variant);

    &.important {
        --color: var(--color-white);
        --background: var(--variant);
        --button-hover-background: var(--color-black);
        --progress-background: var(--color-white);
    }
}
